import Vue from 'vue'
import VueRouter from 'vue-router'
import axiosIns from "@axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'users',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users/:id/show',
    name: 'userdetails',
    component: () => import('@/views/users/UserDetails.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/video',
    name: 'videos',
    component: () => import('@/views/videos/Videos.vue'),
    meta: {
      layout: 'content',
    },
  },
  {

    path: '/communication',
    name: 'communications',
    component: () => import('@/views/communications/Communications.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/communication/:id/show',
    name: 'communicationDetails',
    component: () => import('@/views/communications/CommunicationDetails.vue'),
    meta: {
      layout: 'content',
    },
  },

  {

    path: '/quality',
    name: 'quality',
    component: () => import('@/views/quality/Quality.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const isLogged = localStorage.getItem('accessToken')
  if (to.path === '/forgot-password' || to.path === '/reset-password') return next()

  // eslint-disable-next-line no-mixed-operators
  if (to.path !== '/login' && !isLogged) {
    return next({ name: 'auth-login' })
  }

  return next()
})

export default router
