// eslint-disable-next-line import/no-cycle
import communicationService from '@/store/services/communicationService'

const state = {
  metaCommunication: null,
  field: {
    title: null,
    keyword: null,
    paginate: 10,
    user_id: null,
  },
  title_field: null,
  user_id_field: null,
  keyword_field: null,
  paginate_field: null,
  links_url_field: null,
  communication: {
    id: null,
    user: {
      id: null,
      name: null,
    },
    links: [
      {
        url: null,
      },
    ],
  },
}

const getters = {
  communications: state => (state.metaCommunication ? state.metaCommunication.data : []),
  current_page: state => (state.metaCommunication ? state.metaCommunication.current_page : 0),
  last_page: state => (state.metaCommunication ? state.metaCommunication.last_page : 0),
  per_page: state => (state.metaCommunication ? state.metaCommunication.per_page : 0),
}

const actions = {

  getCommunications({
    commit,
    // eslint-disable-next-line no-shadow
    state,
  }, {
    page,
    field,
  }) {
    if (state.metaCommunication && state.metaCommunication.current_page === page
      && state.title_field === field.title && state.paginate_field === field.paginate
      && state.keyword_field === field.keyword && state.user_id_field === field.user_id
      && state.links_url_field === field.links_url_field) { return state.metaCommunication }

    return communicationService.getCommunicationsList(page, field)
      .then(({ data }) => {
        commit('SET_META_COMMUNICATION', data.result)
        commit('SET_KEYWORD_FIELD', field.keyword)
        commit('SET_PAGINATE_FIELD', field.paginate)
        commit('SET_USER_ID_FIELD', field.user_id)
        commit('SET_TITLE_FIELD', field.title)
        commit('SET_LINKS_URL_FIELD', field.links_url)
        commit('SET_FIELD', field)

        return data
      })
  },

  refreshCommunications({
    commit,
    state,
  }, {
    page,
    field,
  }) {
    return communicationService.getCommunicationsList(page, field)
      .then(({ data }) => {
        commit('SET_META_COMMUNICATION', data.result)
        commit('SET_KEYWORD_FIELD', field.keyword)
        commit('SET_PAGINATE_FIELD', field.paginate)
        commit('SET_TITLE_FIELD', field.title)
        commit('SET_USER_ID_FIELD', field.user_id)
        commit('SET_LINKS_URL_FIELD', field.links_url)
        commit('SET_FIELD', field)

        return data
      })
  },
  addCommunication({
    commit,
    state,
  }, communication) {
    return communicationService.addCommunication(communication)
      .then(({ data }) => {
        commit('ADD_COMMUNICATION', data.result)

        return data
      })
  },
  updateCommunication({
    commit,
    state,
  }, communication) {
    return communicationService.updateCommunication(communication)
      .then(({ data }) => {
        commit('UPDATE_COMMUNICATION', {
          communication: data.result,
          item: communication,
        })

        return data
      })
  },

  deleteCommunication({
    commit,
    state,
  }, communication) {
    return communicationService.deleteCommunication(communication.id)
      .then(({ data }) => {
        commit('UPDATE_COMMUNICATION', {
          communication: data.result,
          item: communication,
        })

        return data
      })
  },

  restoreCommunication({
    commit,
    state,
  }, communication) {
    return communicationService.restoreCommunication(communication.id)
      .then(({ data }) => {
        commit('UPDATE_COMMUNICATION', {
          communication: data.result,
          item: communication,
        })

        return data
      })
  },

  forceDeleteCommunication({
    commit,
    state,
  }, communication) {
    return communicationService.forceDeleteCommunication(communication.id)
      .then(({ data }) => {
        commit('REMOVE_COMMUNICATION', {
          item: communication,
        })

        return data
      })
  },

  getCommunication({
    commit,
    state,
  }, id) {
    return communicationService.getCommunication(id)
      .then(({ data }) => {
        commit('SET_COMMUNICATION', data.result)
      })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  UPDATE_COMMUNICATION(state, {
    communication,
    item,
  }) {
    if (state.metaCommunication) {
      state.metaCommunication.data.splice(state.metaCommunication.data.findIndex(t => t.id === item.id), 1, communication)
    }
    if (state.communication) state.communication = communication
  },

  REMOVE_COMMUNICATION(state, {
    item,
  }) {
    if (state.metaCommunication) {
      state.metaCommunication.data.splice(state.metaCommunication.data.findIndex(t => t.id === item.id), 1)
    }
  },
  // eslint-disable-next-line no-shadow
  SET_COMMUNICATION(state, communication) {
    state.communication = communication
  },
  // eslint-disable-next-line no-shadow
  ADD_COMMUNICATION(state, communication) {
    state.metaCommunication.data.unshift(communication)
  },
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, objet) {
    state.keyword_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, objet) {
    state.user_id_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_TITLE_FIELD(state, objet) {
    state.title_field = objet
  }, // eslint-disable-next-line no-shadow

  // eslint-disable-next-line no-shadow
  SET_META_COMMUNICATION(state, objet) {
    state.metaCommunication = objet
  },
  // eslint-disable-next-line no-shadow
  SET_LINKS_URL_FIELD(state, objet) {
    state.links_url_field = objet
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
