import {
  mdiFileOutline, mdiHomeOutline, mdiAccountGroup, mdiYoutube, mdiBullhornOutline, mdiQualityHigh,
} from '@mdi/js'

export default [
  /*{
    title: 'Tableau de bord',
    icon: mdiHomeOutline,
    to: 'home',
  },*/
  /*{
    title: 'Second Page',
    icon: mdiFileOutline,
    to: 'second-page',
  },*/
  {
    title: 'Utilisateurs',
    icon: mdiAccountGroup,
    to: 'users',
  },
  {
    title: 'Vidéos',
    icon: mdiYoutube,
    to: 'videos',
  },
  {

    title: 'Communications',
    icon: mdiBullhornOutline,
    to: 'communications',
  },

  {
    title: 'Qualités',
    icon: mdiQualityHigh,
    to: 'quality',
  },
]
