import axios from '@axios'

export default {
  getCommunicationsList(page, field) {
    return axios.post(`communications/list?page=${page}`, field)
  },
  addCommunication(field) {
    return axios.post('communications', field)
  },
  updateCommunication(field) {
    return axios.put(`communications/${field.id}`, field)
  },
  getCommunication(id) {
    return axios.get(`communications/${id}`)
  },
  deleteCommunication(id) {
    return axios.delete(`communications/${id}`)
  },
  restoreCommunication(id) {
    return axios.post(`communications/${id}/restore`)
  },

  forceDeleteCommunication(id) {
    return axios.post(`communications/${id}/delete`)
  },


}
