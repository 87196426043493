<template>
  <div>
    <v-snackbar
      v-model="$store.state.user.isNotifVisible"
      bottom
      right
      :color="$store.state.user.isNotifSuccess ? 'primary' : 'error'"
    >
      {{ $store.state.user.notifMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api'

export default {
  name: 'MessageNotification',

  setup() {
    const instance = getCurrentInstance()
  },
}
</script>

<style scoped>

</style>
