// axios
import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : process.env.VUE_APP_DEV_BASE_URL,
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    // eslint-disable-next-line no-param-reassign
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    // eslint-disable-next-line no-param-reassign
    config.headers.common['Access-Control-Allow-Origin'] = '*'

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  // eslint-disable-next-line consistent-return
  error => {
    if (error.response) {
      // eslint-disable-next-line no-restricted-globals
      if ((error.response.status === 401 || error.response.status === 403) && localStorage.getItem('accessToken') !== null) store.dispatch('user/logout').then(() => location.assign('/login')).catch(() => location.assign('/login'))
      // eslint-disable-next-line no-restricted-globals
      else if (error.response.status === 419) location.assign('/login')

      return Promise.reject(error)
    }
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
