import linkService from '@/store/services/linkService'

const state = {
  metaLinks: null,
  field: {
    url: null,
    keyword: null,
    paginate: 30,
    communication_id: null,
  },
  url_field: null,
  keyword_field: null,
  communication_id: null,
  paginate_field: null,
  quality: {
    id: null,
  },
}

const getters = {
  links: state => (state.metaLinks ? state.metaLinks.data : []),
  current_page: state => (state.metaLinks ? state.metaLinks.current_page : 0),
  last_page: state => (state.metaLinks ? state.metaLinks.last_page : 0),
  per_page: state => (state.metaLinks ? state.metaLinks.per_page : 0),
}

const actions = {
  getLinks({ commit, state }, { page, field }) {
    /*if (state.metaLinks && state.metaLinks.current_page === page
      && state.url_field === field.url && state.paginate_field === field.paginate
      && state.communication_id === field.communication_id) {
      return state.metaLinks
    }*/

    return linkService.getLinks(page, field).then(({ data }) => {
      commit('SET_META', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_COMMUNICATION_ID_FIELD', field.communication_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  deleteLink({commit, state}, link) {
    return linkService.deleteLink(link.id)
      .then(({ data }) => {
        commit('DELETE_LINK', {
          item: link,
        })

        return data
      })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_COMMUNICATION_ID_FIELD(state, objet) {
    state.communication_id = objet
  },
  // eslint-disable-next-line no-shadow
  SET_META(state, objet) {
    state.metaLinks = objet
  },

  UPDATE_LINK(state, { link, item}) {
    if (state.metaLinks) {
      state.metaLinks.data.splice(state.metaLinks.data.findIndex(t => t.id === item.id), 1, link)
    }
  },

  DELETE_LINK(state, { item }) {
    if (state.metaLinks) {
      state.metaLinks.data.splice(state.metaLinks.data.findIndex(t => t.id === item.id), 1)
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
