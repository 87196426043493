import axios from '@axios'

export default {
  getVideosList(page, field) {
    return axios.post(`videos/list?page=${page}`, field)
  },
  addVideo(field) {
    return axios.post('videos', field)
  },
  updateVideo(field) {
    return axios.put(`videos/${field.id}`, field)
  },
  getVideo(id) {
    return axios.get(`videos/${id}`)
  },
  deleteVideo(id) {
    return axios.delete(`videos/${id}`)
  },
  restoreVideo(id) {
    return axios.post(`videos/${id}/restore`)
  },
  forceDeleteVideo(id) {
    return axios.post(`videos/${id}/delete`)
  },
}
