import qualityService from '@/store/services/qualityService'
import uploadService from '@/store/services/uploadService'

const state = {
  metaQuality: null,
  field: {
    title: null,
    keyword: null,
    paginate: 10,
    user: null,
    user_id: null,
  },
  title_field: null,
  keyword_field: null,
  user_field: null,
  user_id_field: null,
  paginate_field: null,
  quality: {
    id: null,
  },
  file: null,
  fileCommunicationPdf: null
}

const getters = {
  qualities: state => (state.metaQuality ? state.metaQuality.data : []),
  current_page: state => (state.metaQuality ? state.metaQuality.current_page : 0),
  last_page: state => (state.metaQuality ? state.metaQuality.last_page : 0),
  per_page: state => (state.metaQuality ? state.metaQuality.per_page : 0),
}

const actions = {
  uploadFile({ commit, state }, { model, field }) {
    return uploadService.uploadFile(model, field).then(({ data }) => {
      commit('SET_FILE', data.result)

      return data
    })
  },
  uploadFileCommunicationPdf({ commit, state }, { model, field }) {
    return uploadService.uploadFile(model, field).then(({ data }) => {
      commit('SET_FILE_COMMUNICATION_PDF', data.result)

      return data
    })
  },

  getQualities({ commit, state }, { page, field }) {
    if (state.metaQuality && state.metaQuality.current_page === page
      && state.title_field === field.title && state.paginate_field === field.paginate
      && state.keyword_field === field.keyword && state.user_field === field.user
      && state.user_id_field === field.user_id) {
      return state.metaQuality
    }

    return qualityService.getQualitiesList(page, field).then(({ data }) => {
      commit('SET_META_QUALITY', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_TITLE_FIELD', field.title)
      commit('SET_USER_FIELD', field.user)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshQualities({ commit, state }, { page, field }) {
    return qualityService.getQualitiesList(page, field).then(({ data }) => {
      commit('SET_META_QUALITY', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_TITLE_FIELD', field.title)
      commit('SET_USER_FIELD', field.user)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_FIELD', field)

      return data
    })
  },
  addQuality({ commit, state }, quality) {
    return qualityService.addQuality(quality).then(({ data }) => {
      commit('ADD_QUALITY', data.result)

      return data
    })
  },
  updateQuality({ commit, state }, quality) {
    return qualityService.updateQuality(quality).then(({ data }) => {
      commit('UPDATE_QUALITY', { quality: data.result, item: quality })

      return data
    })
  },

  deleteQuality({ commit, state }, quality) {
    return qualityService.deleteQuality(quality.id).then(({ data }) => {
      commit('UPDATE_QUALITY', { quality: data.result, item: quality })

      return data
    })
  },

  restoreQuality({ commit, state }, quality) {
    return qualityService.restoreQuality(quality.id).then(({ data }) => {
      commit('UPDATE_QUALITY', { quality: data.result, item: quality })

      return data
    })
  },

  forceDeleteQuality({ commit, state }, quality) {
    return qualityService.forceDeleteQuality(quality.id).then(({ data }) => {
      commit('REMOVE_QUALITY', { item: quality })

      return data
    })
  },

  getQuality({ commit, state }, id) {
    return qualityService.getQuality(id).then(({ data }) => {
      commit('SET_QUALITY', data.result)
    })
  },
}

const mutations = {
  UPDATE_QUALITY(state, { quality, item }) {
    if (state.metaQuality) {
      state.metaQuality.data.splice(state.metaQuality.data.findIndex(t => t.id === item.id), 1, quality)
    }
  },

  REMOVE_QUALITY(state, { item }) {
    if (state.metaQuality) {
      state.metaQuality.data.splice(state.metaQuality.data.findIndex(t => t.id === item.id), 1)
    }
  },
  // eslint-disable-next-line no-shadow
  SET_QUALITY(state, quality) {
    state.quality = quality
  },
  // eslint-disable-next-line no-shadow
  ADD_QUALITY(state, quality) {
    state.metaQuality.data.unshift(quality)
  },
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, objet) {
    state.keyword_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_TITLE_FIELD(state, objet) {
    state.title_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, objet) {
    state.user_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, objet) {
    state.user_id_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_META_QUALITY(state, objet) {
    state.metaQuality = objet
  },

  // eslint-disable-next-line no-shadow
  SET_FILE(state, item) {
    state.file = item.chemin
  },
  SET_FILE_COMMUNICATION_PDF(state, item) {
    state.fileCommunicationPdf = item.chemin
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
