import videoService from '@/store/services/videoService'

const state = {
  metaVideo: null,
  field: {
    title: null,
    paginate: 10,
    user: null,
    user_id: null,
    keyword: null,
  },
  title_field: null,
  keyword_field: null,
  user_field: null,
  user_id_field: null,
  paginate_field: null,
  video: {
    id: null,
  },
}

const getters = {
  videos: state => (state.metaVideo ? state.metaVideo.data : []),
  current_page: state => (state.metaVideo ? state.metaVideo.current_page : 0),
  last_page: state => (state.metaVideo ? state.metaVideo.last_page : 0),
  per_page: state => (state.metaVideo ? state.metaVideo.per_page : 0),
}

const actions = {

  getVideos({
    commit,
    state,
  }, {
    page,
    field,
  }) {
    if (state.metaVideo && state.metaVideo.current_page === page
      && state.title_field === field.title && state.paginate_field === field.paginate
      && state.user_field === field.user && state.user_id_field === field.user_id
      && state.keyword_field === field.keyword) {
      return state.metaVideo
    }

    return videoService.getVideosList(page, field)
      .then(({ data }) => {
        commit('SET_META_VIDEO', data.result)
        commit('SET_PAGINATE_FIELD', field.paginate)
        commit('SET_KEYWORD_FIELD', field.keyword)
        commit('SET_TITLE_FIELD', field.title)
        commit('SET_USER_FIELD', field.user)
        commit('SET_USER_ID_FIELD', field.user_id)
        commit('SET_FIELD', field)

        return data
      })
  },

  refreshVideo({
    commit,
    state,
  }, {
    page,
    field,
  }) {
    return videoService.getVideosList(page, field)
      .then(({ data }) => {
        commit('SET_META_VIDEO', data.result)
        commit('SET_PAGINATE_FIELD', field.paginate)
        commit('SET_TITLE_FIELD', field.title)
        commit('SET_KEYWORD_FIELD', field.keyword)
        commit('SET_USER_FIELD', field.user)
        commit('SET_USER_ID_FIELD', field.user_id)
        commit('SET_FIELD', field)

        return data
      })
  },
  addVideo({
    commit,
    state,
  }, video) {
    return videoService.addVideo(video)
      .then(({ data }) => {
        commit('ADD_VIDEO', data.result)

        return data
      })
  },
  updateVideo({
    commit,
    state,
  }, video) {
    return videoService.updateVideo(video)
      .then(({ data }) => {
        commit('UPDATE_VIDEO', {
          video: data.result,
          item: video,
        })

        return data
      })
  },

  deleteVideo({
    commit,
    state,
  }, video) {
    return videoService.deleteVideo(video.id)
      .then(({ data }) => {
        commit('UPDATE_VIDEO', {
          video: data.result,
          item: video,
        })

        return data
      })
  },

  restoreVideo({
    commit,
    state,
  }, video) {
    return videoService.restoreVideo(video.id)
      .then(({ data }) => {
        commit('UPDATE_VIDEO', {
          video: data.result,
          item: video,
        })

        return data
      })
  },

  forceDeleteVideo({
    commit,
    state,
  }, video) {
    return videoService.forceDeleteVideo(video.id)
      .then(({ data }) => {
        commit('REMOVE_VIDEO', {
          item: video,
        })

        return data
      })
  },

  getVideo({
    commit,
    state,
  }, id) {
    return videoService.getVideo(id)
      .then(({ data }) => {
        commit('SET_VIDEO', data.result)
      })
  },
}

const mutations = {
  UPDATE_VIDEO(state, {
    video,
    item,
  }) {
    if (state.metaVideo) {
      state.metaVideo.data.splice(state.metaVideo.data.findIndex(t => t.id === item.id), 1, video)
    }
  },

  REMOVE_VIDEO(state, {
    item,
  }) {
    if (state.metaVideo) {
      state.metaVideo.data.splice(state.metaVideo.data.findIndex(t => t.id === item.id), 1)
    }
  },
  // eslint-disable-next-line no-shadow
  SET_VIDEO(state, video) {
    state.video = video
  },
  // eslint-disable-next-line no-shadow
  ADD_VIDEO(state, video) {
    state.metaVideo.data.unshift(video)
  },
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, objet) {
    state.keyword_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_TITLE_FIELD(state, objet) {
    state.title_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, objet) {
    state.user_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, objet) {
    state.user_id_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_META_VIDEO(state, objet) {
    state.metaVideo = objet
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
