import axios from '@axios'

export default {
  login(field) {
    return axios.post('/login', field)
  },
  logout(deviceToken = null) {
    return axios.get(`logout/${deviceToken}`)
  },
  getCurrentUser(deviceToken = null) {
    return axios.get(`user/${deviceToken}`)
  },

  forgotPassword(email) {
    return axios.get(`forget/password/${email}`)
  },

  resetPassword(field) {
    return axios.post('password/reset/email', field)
  },
}
