import contentKeywordService from '@/store/services/contentKeywordService'

const state = {
  metacontentKeywords: null,
  field: {
    value: null,
    for_video: null,
    paginate: 10,
    for_quality: null,
    video_id: null,
    quality_id: null,
  },
  value_field: null,
  for_video_field: null,
  for_quality: null,
  paginate_field: null,
  video_id_field: null,
  quality_id_field: null,
  quality: {
    id: null,
  },
}

const getters = {
  contentKeywords: state => (state.metacontentKeywords ? state.metacontentKeywords.data : []),
  current_page: state => (state.metacontentKeywords ? state.metacontentKeywords.current_page : 0),
  last_page: state => (state.metacontentKeywords ? state.metacontentKeywords.last_page : 0),
  per_page: state => (state.metacontentKeywords ? state.metacontentKeywords.per_page : 0),
}

const actions = {
  getContentKeywords({ commit, state }, { page, field }) {
    /* if (state.metacontentKeywords && state.metacontentKeywords.current_page === page
      && state.value_field === field.value && state.paginate_field === field.paginate
      && state.for_quality === field.for_quality) {
      return state.metacontentKeywords
    } */

    return contentKeywordService.getContentKeywords(page, field).then(({ data }) => {
      commit('SET_META', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FOR_QUALITY_FIELD', field.for_quality)
      commit('SET_FOR_VIDEO_FIELD', field.for_video)
      commit('SET_VIDEO_ID_FIELD', field.video_id)
      commit('SET_QUALITY_ID_FIELD', field.quality_id)
      commit('SET_FIELD', field)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_FOR_QUALITY_FIELD(state, objet) {
    state.for_quality = objet
  },

  // eslint-disable-next-line no-shadow
  SET_VIDEO_ID_FIELD(state, objet) {
    state.video_id_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_QUALITY_ID_FIELD(state, objet) {
    state.quality_id_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_FOR_VIDEO_FIELD(state, objet) {
    state.for_video_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_META(state, objet) {
    state.metacontentKeywords = objet
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
