import axios from '@axios'

export default {
  getUsersList(page, field) {
    return axios.post(`users/list?page=${page}`, field)
  },
  addUser(field) {
    return axios.post('users', field)
  },
  updateUser(field) {
    return axios.put(`users/${field.id}`, field)
  },
  getUser(id) {
    return axios.get(`users/${id}`)
  },
  deleteUser(id) {
    return axios.delete(`users/${id}`)
  },
  restoreUser(id) {
    return axios.post(`users/${id}/restore`)
  },
  forceDeleteUser(id) {
    return axios.post(`users/${id}/delete`)
  },
}
