import axios from '@axios'

export default {
  getQualitiesList(page, field) {
    return axios.post(`qualities/list?page=${page}`, field)
  },
  addQuality(field) {
    return axios.post('qualities', field)
  },
  updateQuality(field) {
    return axios.put(`qualities/${field.id}`, field)
  },
  getQuality(id) {
    return axios.get(`qualities/${id}`)
  },
  deleteQuality(id) {
    return axios.delete(`qualities/${id}`)
  },
  restoreQuality(id) {
    return axios.post(`qualities/${id}/restore`)
  },
  forceDeleteQuality(id) {
    return axios.post(`qualities/${id}/delete`)
  },
}
