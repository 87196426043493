import axios from '@axios'

export default {
  getDevices(page, field) {
    return axios.post(`devices/list?page=${page}`, field)
  },

  forceDeleteDevice(id) {
    return axios.delete(`devices/${id}/delete`)
  },
}
