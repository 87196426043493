import deviceService from '@/store/services/deviceService'
import userService from "@/store/services/userService";

const state = {
  metaDevices: null,
  field: {
    user_id: null,
    is_active: null,
    paginate: 10,
    agent: null,
  },
  user_id_field: null,
  is_active_field: null,
  agent: null,
  paginate_field: null,
  quality: {
    id: null,
  },
}

const getters = {
  devices: state => (state.metaDevices ? state.metaDevices.data : []),
  current_page: state => (state.metaDevices ? state.metaDevices.current_page : 0),
  last_page: state => (state.metaDevices ? state.metaDevices.last_page : 0),
  per_page: state => (state.metaDevices ? state.metaDevices.per_page : 0),
}

const actions = {
  getDevices({ commit, state }, { page, field }) {
    if (state.metaDevices && state.metaDevices.current_page === page
      && state.user_id_field === field.user_id && state.paginate_field === field.paginate
      && state.agent === field.agent) {
      return state.metaDevices
    }

    return deviceService.getDevices(page, field).then(({ data }) => {
      commit('SET_META', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_AGENT_FIELD', field.agent)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  forceDeleteDevice({ commit, state }, device) {
    return deviceService.forceDeleteDevice(device.id).then(({ data }) => {
      commit('REMOVE_DEVICE', { item: device })

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },

  // eslint-disable-next-line no-shadow
  SET_AGENT_FIELD(state, objet) {
    state.agent = objet
  },

  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, objet) {
    state.user_id = objet
  },
  // eslint-disable-next-line no-shadow
  SET_META(state, objet) {
    state.metaDevices = objet
  },

  REMOVE_DEVICE(state, { item }) {
    if (state.metaDevices) { state.metaDevices.data.splice(state.metaDevices.data.findIndex(t => t.id === item.id), 1) }
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
