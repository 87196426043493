import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import user from './modules/user'
import role from './modules/role'
import video from './modules/video'
import communication from './modules/communication'
import quality from './modules/quality'
import link from './modules/link'
import device from '@/store/modules/device'
import contentKeyword from '@/store/modules/contentKeyword';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    role,
    video,
    communication,
    quality,
    link,
    device,
    contentKeyword,
  },
})
