import authService from '@/store/services/authService'
import userService from '@/store/services/userService'

const state = {
  currentUser: {
    name: null,
    lang: null,
    role: {},
  },
  metaUser: null,
  field: {
    name: null,
    keyword: null,
    paginate: 10,
    role_id: null,
  },
  name_field: null,
  role_id_field: null,
  keyword_field: null,
  paginate_field: null,
  user: {
    id: null,
    role: {
      id: null,
      code: null,
    },
  },
  isNotifVisible: false,
  isNotifSuccess: false,
  notifMessage: null,
  resp_message: null,
  email: null,
}

const getters = {
  users: state => (state.metaUser ? state.metaUser.data : []),
  current_page: state => (state.metaUser ? state.metaUser.current_page : 0),
  last_page: state => (state.metaUser ? state.metaUser.last_page : 0),
  per_page: state => (state.metaUser ? state.metaUser.per_page : 0),
}

const actions = {
  login({ commit, state }, field) {
    return authService.login(field).then(({ data }) => {
      commit('SET_CURRENT_USER', data.result.user)
      commit('SET_TOKEN', data.result.access_token)

      return data
    })
  },

  logout({ commit, state }, deviceToken) {
    return authService.logout(deviceToken).then(({ data }) => {
      commit('REMOVE_TOKEN', null)
      commit('SET_CURRENT_USER', null)
      commit('SET_MESSAGE', data.message)

      return data
    }).catch(({ error }) => {
      console.log(error)

      return error
    })
  },

  // eslint-disable-next-line no-shadow
  getUsers({ commit, state }, { page, field }) {
    if (state.metaUser && state.metaUser.current_page === page
        && state.name_field === field.name && state.paginate_field === field.paginate
        && state.keyword_field === field.keyword && state.role_id_field === field.role_id) return state.metaUser

    return userService.getUsersList(page, field).then(({ data }) => {
      commit('SET_META_USER', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_NAME_FIELD', field.name)
      commit('SET_ROLE_ID_FIELD', field.role_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshUsers({ commit, state }, { page, field }) {
    return userService.getUsersList(page, field).then(({ data }) => {
      commit('SET_META_USER', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_NAME_FIELD', field.name)
      commit('SET_ROLE_ID_FIELD', field.role_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addUser({ commit, state }, user) {
    return userService.addUser(user).then(({ data }) => {
      commit('ADD_USER', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateUser({ commit, state }, user) {
    return userService.updateUser(user).then(({ data }) => {
      commit('UPDATE_USER', { user: data.result, item: user })

      return data
    })
  },
  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteUser({ commit, state }, user) {
    return userService.deleteUser(user.id).then(({ data }) => {
      commit('UPDATE_USER', { user: data.result, item: user })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getCurrentUser({ commit, state }, deviceToken) {
    return authService.getCurrentUser(deviceToken).then(({ data }) => {
      commit('SET_CURRENT_USER', data)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreUser({ commit, state }, user) {
    return userService.restoreUser(user.id).then(({ data }) => {
      commit('UPDATE_USER', { user: data.result, item: user })

      return data
    })
  },

  forceDeleteUser({ commit, state }, user) {
    return userService.forceDeleteUser(user.id).then(({ data }) => {
      commit('REMOVE_USER', { item: user })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getUser({ commit, state }, id) {
    return userService.getUser(id).then(({ data }) => {
      commit('SET_USER', data.result)
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  forgotPassRequest({ commit, state }, email) {
    return authService.forgotPassword(email).then(({ data }) => {
      commit('SET_EMAIL', email)
      commit('SET_MESSAGE', data.message)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  resetPass({ commit, state }, field) {
    return authService.resetPassword(field).then(({ data }) => {
      commit('SET_MESSAGE', data.message)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_EMAIL(state, email) {
    state.email = email
  },

  // eslint-disable-next-line no-shadow
  UPDATE_USER(state, { user, item }) {
    if (state.metaUser) { state.metaUser.data.splice(state.metaUser.data.findIndex(t => t.id === item.id), 1, user) }
  },
  REMOVE_USER(state, { item }) {
    if (state.metaUser) { state.metaUser.data.splice(state.metaUser.data.findIndex(t => t.id === item.id), 1) }
  },
  // eslint-disable-next-line no-shadow
  SET_USER(state, user) {
    state.user = user
  },
  // eslint-disable-next-line no-shadow
  ADD_USER(state, user) {
    state.metaUser.data.unshift(user)
  },
  // eslint-disable-next-line no-shadow
  SET_FIELD(state, objet) {
    state.field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, objet) {
    state.keyword_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, objet) {
    state.paginate_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_NAME_FIELD(state, objet) {
    state.name_field = objet
  }, // eslint-disable-next-line no-shadow
  SET_ROLE_ID_FIELD(state, objet) {
    state.role_id_field = objet
  },
  // eslint-disable-next-line no-shadow
  SET_META_USER(state, objet) {
    state.metaUser = objet
  },

  // eslint-disable-next-line no-shadow
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser
  },

  // eslint-disable-next-line no-shadow
  REMOVE_TOKEN(state, token) {
    state.token = token
    localStorage.removeItem('accessToken')
  },

  // eslint-disable-next-line no-shadow
  SET_MESSAGE(state, message) {
    state.resp_message = message
  },

  // eslint-disable-next-line no-shadow
  SET_TOKEN(state, token) {
    state.token = token
    localStorage.setItem('accessToken', token)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
