import axios from '@axios'

export default {
  getLinks(page, field) {
    return axios.post(`links/list?page=${page}`, field)
  },

  deleteLink(id) {
    return axios.delete(`links/${id}/delete`)
  },
}
