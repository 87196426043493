import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'


const firebaseConfig = {
  apiKey: 'AIzaSyBZk44RoA-vZulip-GBSqXrWIUarmtx2fA',
  authDomain: 'espas-moutier.firebaseapp.com',
  projectId: 'espas-moutier',
  storageBucket: 'espas-moutier.appspot.com',
  messagingSenderId: '514614580649',
  appId: '1:514614580649:web:936049f7e88f287792a0b8',
}
const firebaseApp = initializeApp(firebaseConfig)

const messaging = getMessaging(firebaseApp)
console.log(localStorage.getItem('device_token'))
if (localStorage.getItem('device_token') === null) {
  if (messaging) {
    navigator.serviceWorker.register('firebase-messaging-sw.js', {
      type: 'module',
      scope: '/'
    }).then(serviceWorkerRegistration => {
      getToken(messaging, {
        serviceWorkerRegistration,
        vapidKey: 'BP5fb7l04AGHHKqEcdQUgOe5oiiNp_X3R8-bzs95QFueTtDCUea2ZNudBtSC_kk4KNKCsGJyd8M9TJIcPMf3iIk',
      }).then(currentToken => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          localStorage.setItem('device_token', currentToken)
          console.log(currentToken)
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch(err => {
        console.log('An error occurred while retrieving token. ', err)
      })
    })
  }
}
onMessage(messaging, payload => {
  console.log('Message received. ', payload)

  const notificationTitle = payload.notification.title
  const notificationOptions = {
    body: payload.notification.body,
    //icon: '@/assets/images/svg/mboa_cov_ico.svg',
  }
  Notification.requestPermission(result => {
    if (result === 'granted') {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(payload.notification.title, {
          body: payload.notification.body,
        })
      })
    }
  })

  /*store.dispatch('notification/refreshNotificationsList', { page: 1, field: store.state.notification.field })
  refreshData(payload)*/
  /*Notification.requestPermission(result => {
    if (result === 'granted') {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(notificationTitle, notificationOptions)
      })
    }
  })*/
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
